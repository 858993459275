<template>
	<div class="staff">
		<!-- ##### Viral News Breadcumb Area Start ##### -->
		<div class="viral-news-breadcumb-area section-padding-50">
			<div class="container h-100">
				<div class="row h-100 align-items-center">
					<!-- Breadcumb Area -->
					<div class="col-12 col-md-4">
						<h3>求人・採用情報</h3>
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item"><a href="/">Home</a></li>
								<li class="breadcrumb-item active" aria-current="page">
									求人・採用情報
								</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Viral News Breadcumb Area End ##### -->

		<!-- ##### Contact Form Area Start ##### -->
		<div class="block section-padding-50">
			<div class="page container">
				<div class="row outline">
					<div class="col-12 col-md-12 headline">
						<h4>はじめに</h4>
					</div>
					<div class="col-12 col-lg-4 outline__image">
						<img
							class="img-responsive card-img-top"
							alt="技術職"
							src="/img/about/recruit/01_hazimeni.jpg"
						/>
					</div>
					<div class="col-12 col-lg-8 outline__text">
						<p>
							私たちは、チューニングやパーツ開発などをしているので、一般的な整備とは少々異なりますので特殊な印象を持たれるかもしれません。しかしながら、ディーラー出身や新卒で入社した社員が長年の業務経験からチューニング技術を取得していますが、入社時はチューニングのことは何も知らない白紙状態で入社していますので、チューニングに興味があるだけで大歓迎です。
							<br />是非チャレンジしてみてください。
							<br />小さい会社でありますので、全てを満たすことはできませんが遣り甲斐のある会社でありますので是非とも一緒に働いてみませんか！ご応募をお待ちしております。
						</p>
					</div>
				</div>

				<div class="row outline mt-5">
					<div class="col-12 col-md-12 headline">
						<h4>職場のイメージ</h4>
					</div>
					<div class="col-12 col-lg-4 outline__image">
						<img
							class="img-responsive card-img-top"
							alt="技術職"
							src="/img/about/recruit/05_shokuba_no_image.jpg"
						/>
					</div>
					<div class="col-12 col-lg-8 outline__text">
						<p>
							事務スペース、ショールーム（フロント）、２つの工場、車両保管スペース、のスペースがあります。
							<br /><strong>■工場</strong
							><br />リフトは整備用5基とアライメント用が1基あり、2000馬力対応のシャシダイ、4輪アライメントテスター、室温管理できるクリーンルームにはダンパーテスター、旋盤等の工作機械など。メカニック事務所に３Dスキャナーを設置、メイン工場内には冷暖房を完備しています。
							<br /><strong>■ショールーム</strong
							><br />2021年8月に改装して、スタッフ4名以上が常駐できるデスクがあり、お客さまが18名座れるスペースがあり、コーヒーとレッドブルのドリンクサービスを設けています。
							<br /><strong>■事務スペース</strong
							><br />ミーティングなども出来る少々広めでソーシャルディスタンスにも充分対応できるスペースです。休憩できるレストルームもあります。
							<br /><strong>■車両保管スペース</strong
							><br />社員の自家用車を駐車することができます。
						</p>
					</div>

					<div class="col-12 col-md-12 headline">
						<h4>ASSISTとは</h4>
					</div>
					<div class="col-12 col-lg-4 outline__image">
						<img
							class="img-responsive card-img-top"
							alt="技術職"
							src="/img/about/recruit/02_assist.jpg"
						/>
					</div>
					<div class="col-12 col-lg-8 outline__text">
						<p>
							BMWのクルマづくりやモータースポーツのサポートをしている会社です。
							<br />クルマ好きの趣味を思うがままに楽しめるように知識と技術を深く追求しているBMW専門店です。
							<br />クルマづくりはチューニングとパーツ開発を主としてします。
							<br />モータースポーツではドライビングレッスン、サーキットサポート、走行会、競技参加などで、お客さまに楽しんでもらっています。
							<br />ウェブサイトやSNSで情報発信して、全国から車両を持ち込まれており、海外からは欧米やアジアのパーツ販売が好調で海外ファンも徐々に増えて認知度を実感できるようになってきました。
							<br />私たちのクルマづくに共感してくれるファンを世界中に増やすために、クルマづくりはもちろんのこと情報技術やブランドづくりにも積極的に取り組んでいます。
						</p>
					</div>

					<div class="col-12 col-md-12 headline">
						<h4>事業内容</h4>
					</div>
					<div class="col-12 col-lg-4 outline__image">
						<img
							class="img-responsive card-img-top"
							alt="技術職"
							src="/img/about/recruit/03_zigyou_naiyou.jpg"
						/>
					</div>
					<div class="col-12 col-lg-8 outline__text">
						<ul>
							<li>
								BMWチューニングの提案と弊社工場にてチューニング作業やパーツの取付け作業。
							</li>
							<li>車検、オイル交換などの一般的なメンテナンス作業。</li>
							<li>モータースポーツ用の車両製作とサーキットサポート。</li>
							<li>
								自社イベントの開催（サーキット走行会、ドライビングレッスン、親睦会など）
							</li>
							<li>
								モータースポーツ参加やメディア出演、雑誌取材によるプロモーション活動。
							</li>
							<li>SNSやウェブサイトによる国内および海外へパーツの通販。</li>
							<li>海外からのパーツ輸入、海外の弊社代理店へパーツを輸出。</li>
							<li>オリジナル製品の設計開発および販売。（LAPTORRブランド）</li>
						</ul>
					</div>

					<div class="col-12 col-md-12 headline">
						<h4>会社の魅力</h4>
					</div>
					<div class="col-12 col-lg-4 outline__image">
						<img
							class="img-responsive card-img-top"
							alt="技術職"
							src="/img/about/recruit/04_kaisha_no_miryoku.jpg"
						/>
					</div>
					<div class="col-12 col-lg-8 outline__text">
						<ul>
							<li>
								チューニング未経験でも大丈夫です、入社してからスキルアップすることが出来ます。
							</li>
							<li>
								業務範囲が広いのでスキルに見合ったポジションが必ずあります。
							</li>
							<li>
								成長に応じて業務内容をレベルアップできれば、それに伴って報酬もアップします。
							</li>
							<li>業界最前線であることから貴重な情報や知識を入手できます。</li>
							<li>
								お客さまはフレンドリーな方が多いので接客応対が楽しいです。
							</li>
							<li>
								お客さまが歓ばれることが多いので遣り甲斐に繋がってきます。
							</li>
							<li>
								自分のスキルを超えた業務は廻りのベテランスタッフがヘルプしますので安心です。
							</li>
							<li>
								自分の得意な仕事や成長を無限に伸ばすことができるので遣り甲斐は充分にあります。
							</li>
							<li>
								チャレンジ旺盛なスタッフにはチャレンジできる機会を多くつくります。
							</li>
							<li>
								挑戦は避けて無難な業務を好むスタッフには安定したポジションに配属しますので安心です。
							</li>
							<li>日曜日に有休を取れるようにしています。</li>
							<li>
								新しい経営スタイルを積極的に取り入れていますので仕事環境は日々変革しています。
							</li>
						</ul>
					</div>
				</div>

				<div class="row outline">
					<div class="col-12 col-md-12 headline">
						<h3>求人一覧</h3>
					</div>
					<div class="col-12">
						<p class="text-center">
							<a href="/recruit/technical" class="btn contact-btn mx-3 mt-3"
								>メカニック<br />
								<small>募集要項ページへ移動します</small></a
							>
							<!--<a href="/recruit/general" class="btn contact-btn mx-3 mt-3"
								>一般事務<br />
								<small>募集要項ページへ移動します</small></a
							>-->
						</p>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Contact Form Area End ##### -->
	</div>
</template>

<script>
//import Sidebar from "@/components/Sidebar.vue";
export default {
	title: "求人・採用情報",
	components: {},
};
</script>

<style scoped lang="scss">
.outline {
	h4 {
		font-size: 1.2rem;
		position: relative;
		padding: 1.2rem;
		background-color: #f3f5f8;
		&:before {
			content: "";
			position: absolute;
			display: block;
			width: 0;
			height: 0;
			left: -15px;
			top: 20px;
			border-right: 15px solid #f3f5f8;
			border-top: 15px solid transparent;
			border-bottom: 15px solid transparent;
		}
	}
	&__image img {
		margin-bottom: 1rem;
	}
	&__text {
		margin-bottom: 1rem;
	}
}
.headline {
	margin-bottom: 1rem;
}

.detail {
	.card-title {
		font-size: 1.2rem;
		i {
			font-size: 0.8rem;
		}
	}
}
.btn {
	width: 30rem;
}
p {
	color: #212529;
}
table {
	th {
		width: 20%;
	}
}
</style>
